.links-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.social-icon {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.75);
    font-size: 25px;
    transition: transform 100ms ease-in-out;
}

@media screen and (max-width: 576px) {
    .social-icon:active {
        transform: scale(1.2);
    }

    .links-container {
        width: 100%;
    }
}

@media screen and (min-width: 577px) {
    .social-icon:hover {
        transform: scale(1.2);
    }

    .links-container {
        width: 390px;
    }
}