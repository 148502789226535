@media screen and (max-width: 576px) {
  .spacer-row {
    height: 10%;
  }

  .top-row {
    height: 75%;
  }

  .bottom-row {
    height: 15%;

    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .title-text {
    font-size: 15vw;
    overflow-wrap: break-word;
  }

  .button-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 30%;
    width: 100%;
    margin-top: 10%;
  }

  .subtitle {
    height: 25px;
    line-height: 25px;
    text-align: center;
    font-size: 5vw;
    letter-spacing: 0.5em;
    text-indent: 0.5em;
  }

  .animated-border:after {
    display:block;
    content: '';
    border-bottom: solid 1px #fffffffa;
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
  }

  .animated-border:after {
    margin: 0.25em 0.5em 0em 0.5em;
  }

  .active-border:after {
    display:block;
    content: '';
    border-bottom: solid 1px #fffffffa !important;
    margin: 0.25em 0.5em 0em 0.5em;
    transform: scaleX(1);  
    transition: transform 250ms ease-in-out;
  }

  .active-border:active:after {
    transform: scaleX(0);
  }
  
  @keyframes bot-drawer {
      from {
        transform: translate(0px, 0px);
      }
      to {
        transform: translate(0px, -80px);
      }
  }
}

@media screen and (min-width: 577px) {
  .spacer-row {
    height: 40%;
  }

  .top-row {
    height: 20%;
  }

  .bottom-row {
    height: 40%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-text {
    font-size: 9vw;
  }

  .button-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40%;
    width: 50%;
    margin-top: 25px;
  }

  .subtitle {
    font-size: 1.2vw;
    letter-spacing: 1em;
    text-indent: 1em;
  }

  .animated-border:after {
    margin: 1em 1em 0em 1em;
  }

  .animated-border:after {
    display:block;
    content: '';
    border-bottom: solid 1px #fffffffa;
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
  }

  .animated-border:hover:after
  {
    transform: scaleX(1);
  }

  .active-border:after {
    margin: 1em 1em 0em 1em;
  }

  .active-border:after {
    display:block;
    content: '';
    border-bottom: solid 1px #fffffffa !important;
    transform: scaleX(1);  
    transition: transform 250ms ease-in-out;
  }
}

.main-container {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spacer-row {
  width: 100%;
}

.top-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.title-container {
  text-align: center;
  width: 100%;
  font-style: italic;
  font-family: 'Orbitron', sans-serif;
}

.subtitle {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;

  color: rgba(165, 141, 141, .4);
  text-transform: uppercase;

  animation: glitch-2 5s 5.02s infinite;
  z-index: 10000;

  cursor: pointer;
}

.bottom-row {
  width: 100%;
}