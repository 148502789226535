.glitch {
    padding-right: 10px;
    color: rgb(223, 191, 191);
    position: relative;
    // margin: 70px 200px;
    animation: glitch 5s 5s infinite;
}
  
.glitch::before {
    content: attr(data-text);
    position: absolute;
    left: -4px;
    text-shadow: -5px 0 rgba(216, 211, 211, 0.6);
    overflow: hidden;
    top: 0;
    animation: noise-1 3s linear infinite alternate-reverse, glitch 5s 5.05s infinite;
    width: 100%;
}
  
.glitch::after {
    content: attr(data-text);
    position: absolute;
    left: -4px;
    text-shadow: -5px 0 rgba(95, 94, 94, 0.6);
    overflow: hidden;
    top: 0;
    animation: noise-2 3s linear infinite alternate-reverse, glitch 5s 5s infinite;
    width: 100%;
}

@keyframes glitch-2 {
    1%{
      transform: rotateX(10deg);
    }
    2%{
      transform: rotateX(0deg);
    }
}

@keyframes noise-1 {
    $steps: 30;
    @for $i from 1 through $steps {
      #{percentage($i*(1/$steps))} {
        $top: random(100);
        $bottom: random(101 - $top);
        clip-path: inset(#{$top}px 0 #{$bottom}px 0);
      }
    }
}
  
@keyframes noise-2 {
    $steps: 30;
    @for $i from 0 through $steps {
      #{percentage($i*(1/$steps))} {
        $top: random(100);
        $bottom: random(101 - $top);
        clip-path: inset(#{$top}px 0 #{$bottom}px 0);
      }
    }
}
  
.scanlines {
    z-index: 100;
    overflow: hidden;
    mix-blend-mode: difference;
}
  
.scanlines::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 120%;
    top: -50px;
    left: 0;
    align-items: stretch;
    background: repeating-linear-gradient(
      to bottom,
      transparent 0%,
      rgba(255, 255, 255, 0.05) .5%,
      transparent 1%
    );
  
    animation: fudge 7s ease-in-out alternate infinite;
}
  
  
@keyframes fudge {
    from {
      transform: translate(0px, 0px);
    }
    to {
      transform: translate(0px, 20px);
    }
}