@media screen and (max-width: 576px) {
    .spotify-embed-container {
        width: 100%;
    }

    .spotify-embed {
        width: 100% !important;
        transition: left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);
    }

    .bottom-wrapper {
        width: 100%;
    }
}

.links-container {
    margin-bottom: 10px;
    height: 40px;
}

.spotify-embed-container {
    z-index: 10000;
    pointer-events: all;
}

.spotify-embed
{
    display: block;
    z-index: 100001;
    -webkit-animation: fadeInFromNone 0.5s ease-out;
    -moz-animation: fadeInFromNone 0.5s ease-out;
    -o-animation: fadeInFromNone 0.5s ease-out;
    animation: fadeInFromNone 0.5s ease-out;
}

.bottom-wrapper {
    position: absolute;
    bottom: -200px;

    height: 250px;
    
    z-index: 500001;
    transition: 1s;
}

.slide-listen {
    transition: 1s;
    bottom: -120px !important;
}

.slide-contact-form {
    transition: 1s;
    bottom: 0px !important;
}

.slide-contact-success {
    transition: 1s;
    bottom: -100px !important;
}

@-webkit-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-moz-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-o-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}