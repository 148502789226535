.background-video-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: black;
}

.title {
    width: 100%;
    height: 100%;
}