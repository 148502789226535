#background-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 20%;
    background-color: black;
    -o-filter: blur(15px);
    filter: blur(15px);
    transform: scale(1.3);
}