.button {
    width: 95%;
}

.button-primary {
    display:inline-block;
    padding:0.5em 3em;
    border:0.16em solid #FFFFFF;
    margin:0 0.3em 0.3em 0;
    box-sizing: border-box;
    text-decoration:none;
    text-transform:uppercase;
    font-family:'Roboto',sans-serif;
    font-weight:400;
    color:#FFFFFF;
    text-align:center;
    transition: all 0.15s;
}

.button-primary:hover {
    color:#DDDDDD;
    border-color:#DDDDDD;
}

.button-primary:active {
    color:#BBBBBB;
    border-color:#BBBBBB;
}

@media all and (max-width:30em) {
    .button-primary {
        display:block;
        margin:0.4em auto;
    }
}