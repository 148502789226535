.contact-container {
    width: 100%;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    height: 200px;
    width: 100%;

    cursor: pointer;
}

.contact-success {
    height: 50px;
    font-family: 'Orbitron', sans-serif;
    font-size: 50px;
    color: white;
    opacity: 75% !important;
    fill-opacity: 75% !important;
}

.name-email-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 24%;
    margin: 8px 0px 4px 0px;
    width: 95%;
}

#left-input {
    margin-right: 4px;
}

#right-input {
    margin-left: 4px;
}

.submit-button {
    color: white;
    margin: 4px 4px 8px 4px !important;
    transition: 0.5s;
}

.submit-button:hover {
    border-color: rgba(255,255,255,0.5) !important;
    transition: 0.5s;
}

.input {
    border: 0px;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
}

.input-error {
    outline: none !important;
    border: 2px solid red !important;
}

.standard-text-input {
    width: 50%;
    height: 100%;
    padding: 4px;
}

.body-text-input {
    height: 76%;
    padding: 5px;
    width: 95%;
    margin: 4px 0px 4px 0px;
}

textarea, input {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    font-size: 15px;
}

.input {
    border: 2px solid #ffffff00;
    transition: 150ms;
}

.input:focus {
    outline: none !important;
    border:2px solid #858585ac;
    transition: 150ms;
}